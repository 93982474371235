
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Order = {
  id: number;
  status: {
    id: number;
    name: string;
  };
};

type Status = {
  id: number;

  color: string;
};

@Component({
  filters: {
    date_entered(value, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .format("Do MMMM, Y");
    }
  }
})
export default class OrderComponent extends Vue {
  @Prop(Object) readonly order!: Order;

  private orderStatuses: Status[] = [
    { id: 1, color: "warning" }, // open
    { id: 2, color: "success" }, // confirmed
    { id: 3, color: "info" }, // assigned
    { id: 4, color: "info" }, // in progress
    { id: 5, color: "info" }, // reported
    { id: 6, color: "grey" } // cancelled
  ];

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get statusChipColor(): string {
    const status: undefined | Status = this.orderStatuses.find(
      (status: Status) => status.id == this.order.status.id
    );

    return status?.color ?? "info";
  }
}
